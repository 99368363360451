import React, { useEffect, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { Card } from './Card';
import { Card as CardType, ValidSetInfo } from './types';
import { cardFromString, generateUniqueCards, isValidSet } from './utils';
import { Help } from './Help';
import {
  standardColor,
  protanopiaColor,
  deuteranopiaColor,
  tritanopiaColor,
} from './colorPalettes';

import './App.css';
import styles from './Board.module.css';
import cardStyles from './Card.module.css';
import { Header } from './Header';
import { ColorPaletteContextProvider } from './ColorContext';

const transitionDuration = 500;

function App() {
  const [cards, setCards] = useState<Set<string>>(new Set());
  const [selected, setSelected] = useState<Set<number>>(new Set());
  const [createdSetsCount, setCreatedSetsCount] = useState(0);
  const [longestSetsRun, setLongestSetsRun] = useState(0);
  const [useWideBoard, setUseWideBoard] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [colorPaletteIndex, setColorPaletteIndex] = useState<number>(0);

  const colorPalettes = [
    standardColor,
    protanopiaColor,
    // deuteranopiaColor,
    // tritanopiaColor,
  ];

  useEffect(() => {
    setCards(generateUniqueCards());
    readSavedScore();
  }, []);

  const readSavedScore = () => {
    const oldCurrentScore = Number(localStorage.getItem('score'));
    let longestRun = Number(localStorage.getItem('run'));

    if (oldCurrentScore) {
      // just in case Mom had a score to keep
      if (oldCurrentScore > longestRun) longestRun = oldCurrentScore;
      localStorage.removeItem('score');
    }
    setLongestSetsRun(longestRun);
  };

  const saveSavedScore = (newScore: number) => {
    localStorage.setItem('score', newScore.toString());
    if (newScore > longestSetsRun) {
      localStorage.setItem('run', newScore.toString());
    }
  };

  const setCardsWithDelay = (cardsSet: Set<string>) => {
    setTimeout(() => setCards(cardsSet), transitionDuration);
  };

  const checkForValidSet = () => {
    const selectedCards = Array.from(cards)
      .filter((card, idx) => selected.has(idx));

    if (isValidSet(selectedCards.map(cardFromString)).valid) {
      const cardsArray = Array.from(cards);
      cardsArray // remove set from cards
        .filter((_, idx) => selected.has(idx))
        .forEach(card => cards.delete(card));
      const newCardSet = Array.from(generateUniqueCards(selected.size, cards));

      if (cards.size < 12) {
        // replace cards in-place
        Array.from(selected).forEach((selectedCardIndex, idx) =>
          cardsArray[selectedCardIndex] = newCardSet[idx]);
        setCardsWithDelay(new Set(cardsArray));
      } else {
        setCardsWithDelay(new Set(cards));
      }

      updateScore(createdSetsCount + 1);
      clearSelected();
      setTimeout(() => setUseWideBoard(false), transitionDuration);
    }
  };

  const updateScore = (newScore: number) => {
    saveSavedScore(newScore);
    setCreatedSetsCount(newScore);
    if (newScore > longestSetsRun) {
      setLongestSetsRun(newScore);
    }
  }

  const toggleSelectedCard = (cardIndex: number) => {
    if (selected.has(cardIndex)) {
      selected.delete(cardIndex);
      setSelected(new Set(selected));
      return;
    }

    if (selected.size === 3) return;

    selected.add(cardIndex);
    setSelected(new Set(selected));
    if (selected.size === 3) {
      checkForValidSet();
    }
  };

  const refreshBoard = () => {
    updateScore(0);
    setCards(new Set());
    clearSelected();
    setTimeout(() => {
      setUseWideBoard(false);
      setCards(generateUniqueCards());
    }, transitionDuration);
  };

  const clearSelected = () => setSelected(new Set());

  const selectedCards = Array.from(cards).filter((_, idx) => selected.has(idx));
  const validSetInfo = isValidSet(selectedCards.map(cardFromString));

  const drawThreeCards = () => {
    setUseWideBoard(true);
    clearSelected();
    const newCards = Array.from(generateUniqueCards(3, cards));
    const newCardSet = Array.from(cards);
    
    newCards.forEach((card, idx) => newCardSet.splice(4 * idx + 4 + idx, 0, card));

    setCards(new Set(newCardSet));
  };

  const cssStyles = {
    '--columns': useWideBoard ? 5 : 4,
  };

  const cycleColorPalette = () => {
    const newIdx = (colorPaletteIndex + 1) % colorPalettes.length;
    setColorPaletteIndex(newIdx);
  };

  return (
    <div className="App">
      <ColorPaletteContextProvider
        value={{
          colorPalette: colorPalettes[colorPaletteIndex],
          cycleColorPalette,
          currentPaletteIndex: colorPaletteIndex,
        }}
      >
        <div>
          <Header
            currentScore={createdSetsCount}
            bestScore={longestSetsRun}
            cycleColorPalette={cycleColorPalette}
          />
          <div className={styles.feedback}>
            <div>
              <button onClick={() => refreshBoard()}>New Board</button>
              <button onClick={() => drawThreeCards()} disabled={useWideBoard}>Draw 3</button>
              <button onClick={() => setShowHelp(!showHelp)}>
                {showHelp ? 'Hide' : 'Show'} Help
              </button>
            </div>

            <Help
              show={showHelp}
              selectedCards={selectedCards}
              validSetInfo={validSetInfo as ValidSetInfo}
            />
          </div>
        </div>
        
        <div className={styles.board} style={cssStyles as React.CSSProperties}>
          <TransitionGroup component={null}>
            {Array.from(cards)
              .map(card => {
                const newCard = cardFromString(card);
                newCard.stringValue = card;
                return newCard;
              })
              .map((card: CardType, idx) =>
                <CSSTransition
                  key={`card-${card.stringValue}`}
                  timeout={transitionDuration}
                  classNames={{
                    enter: cardStyles.cardEnter,
                    enterActive: cardStyles.cardEnterActive,
                    exit: cardStyles.cardExit,
                    exitActive: cardStyles.cardExitActive,
                  }}
                >
                  <Card
                    index={idx}
                    {...card}
                    active={selected.has(idx)}
                    onClick={() => toggleSelectedCard(idx)}
                    styles={{ '--card-transition-duration': `${transitionDuration}ms` } as React.CSSProperties}
                  />
                </CSSTransition>
            )}
          </TransitionGroup>
        </div>
      </ColorPaletteContextProvider>
    </div>
  );
}

export default App;
