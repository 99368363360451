export const standardColor = {
  red: '#f00',
  green: '#23b019',
  blue: '#194ec8',
};

export const protanopiaColor = { // no red
  red: 'rgb(0, 0, 0)',
  green: 'rgb(0, 255, 127)',
  blue: 'rgb(0, 0, 255)',
};

export const deuteranopiaColor = { // no green
  red: '',
  green: '',
  blue: '',
};

export const tritanopiaColor = { // no blue
  red: '',
  green: '',
  blue: '',
};

export const colorPalettes = {
  standardColor,
  protanopiaColor,
  deuteranopiaColor,
  tritanopiaColor,
};