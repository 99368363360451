import { useState, useContext } from 'react';
import { ColorPaletteContext } from './ColorContext';
import styles from './Settings.module.css';

interface IconProps {
  width: number;
  height: number;
}

const CogIcon: React.FC<IconProps> = ({ width, height }) => (
  <svg viewBox="0 0 18 18" width={width} height={height}>
    <path
      d="M10.263 1.1a8.046 8.046 0 0 0-2.526 0l-.332 1.765a6.378 6.378 0 0 0-1.615.669L4.307 2.521a8.006 8.006 0 0 0-1.786 1.786L3.534 5.79a6.378 6.378 0 0 0-.669 1.615L1.1 7.737a8.046 8.046 0 0 0 0 2.526l1.765.332c.148.567.373 1.11.669 1.615l-1.013 1.483a8.006 8.006 0 0 0 1.786 1.786l1.483-1.013a6.378 6.378 0 0 0 1.615.669l.332 1.765a8.046 8.046 0 0 0 2.526 0l.332-1.765a6.378 6.378 0 0 0 1.615-.669l1.483 1.013a8.006 8.006 0 0 0 1.786-1.786l-1.013-1.483a6.378 6.378 0 0 0 .669-1.615l1.765-.332a8.046 8.046 0 0 0 0-2.526l-1.765-.332a6.378 6.378 0 0 0-.669-1.615l1.013-1.483a8.006 8.006 0 0 0-1.786-1.786L12.21 3.534a6.378 6.378 0 0 0-1.615-.669L10.263 1.1ZM9 7.4a1.6 1.6 0 1 1-.002 3.202A1.6 1.6 0 0 1 9 7.4Z"
    />
  </svg>
);

const EyeIcon: React.FC<IconProps> = ({ width, height }) => (
  <svg viewBox="0 0 18 11" width={width} height={height}>
    <path d="M64.411 296.353c70.178-121.746 203.075-183.008 336.126-183.208 133.317-.199 266.788 60.908 337.463 183.923l-.415.72c-70.794 122.539-203.998 183.403-337.048 183.203-133.318-.199-266.48-61.707-336.537-183.923l.411-.715Z" style={{ fill: 'none', stroke: '#000', strokeWidth: '43px' }} transform="matrix(.0248 0 0 .0248 -.948 -2.036)"/>
    <circle r="149.858" style={{ fill: '#ebebeb', fillOpacity: '0', stroke: '#000', strokeWidth: '41.88px' }} fill="none" transform="matrix(.02547 0 0 .02547 9 5.334)"/>
    <path d="M7.947 2.769A2.773 2.773 0 1 1 6.435 4.28L9 5.334 7.947 2.769Z"/>
  </svg>
);

export const Settings: React.FC = () => {
  const { currentPaletteIndex, cycleColorPalette } = useContext(ColorPaletteContext);
  const [open, setOpen] = useState(false);

  const triggerClasses = `${styles.trigger} ${open ? styles.triggerOpen : ''}`;

  return (
    <>
      <div className={styles.settings}>
        <div className={triggerClasses} onClick={() => cycleColorPalette()}>
          <EyeIcon width={24} height={24} />
          <div className={styles.index}>{currentPaletteIndex + 1}</div>
        </div>
      </div>

      {open && (
        <>
          <div className={styles.overlay} onClick={() => setOpen(false)}>
            <div className={styles.modal}>

            </div>
          </div>
        </>
      )}
    </>
  );
};
