import { ValidSetInfo } from './types';
import { cardFromString } from './utils';

import styles from './Board.module.css';

interface Props {
  show: boolean;
  selectedCards: string[];
  validSetInfo: ValidSetInfo;
}

export const Help: React.FC<Props> = ({ show, selectedCards, validSetInfo }) => {
  if (!show) return null;

  const getSelectedCardsProperty = (property: 'number' | 'shape' | 'color' | 'fill') => {
    return selectedCards
      .map(cardFromString)
      .map(c => c[`${property}Name`] ?? c[property]);
  };

  return (
    <div className={styles.help}>
      {selectedCards.length !== 3 && (
        <p>
          A set must contain three cards.<br/>
          <span className={styles.italic}>
            If you're stuck, click "Draw 3" to draw three more cards, or click "New Board" to start with a new set of cards. ("New Board" will reset the score to 0.)
          </span>
        </p>
      )}
      {selectedCards.length === 3 &&
        <>
          {(!validSetInfo.allShapesSame && !validSetInfo.allShapesDifferent) &&
            <p>
              The <strong>shape</strong> of each card must be either all the same or all different.<br/>
              <span className={styles.italic}>You have {getSelectedCardsProperty('shape').join(', ')}.</span>
            </p>
          }
          {(!validSetInfo.allColorsSame && !validSetInfo.allColorsDifferent) &&
            <p>
              The <strong>color</strong> of each card must be either all the same or all different.<br/>
              <span className={styles.italic}>You have {getSelectedCardsProperty('color').join(', ')}.</span>
            </p>
          }
          {(!validSetInfo.allFillsSame && !validSetInfo.allFillsDifferent) &&
            <p>
              The <strong>fill</strong> of each shape must be either all the same or all different.<br/>
              <span className={styles.italic}>You have {getSelectedCardsProperty('fill').join(', ')}.</span>
            </p>
          }
          {(!validSetInfo.allNumbersSame && !validSetInfo.allNumbersDifferent) &&
            <p>
              The <strong>number</strong> of shapes on each card must be either all the same or all different.<br/>
              <span className={styles.italic}>You have {getSelectedCardsProperty('number').join(', ')}.</span>
            </p>
          }
        </>
      }
    </div>
  );
};