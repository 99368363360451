import { Score } from "./Score";
import { Settings } from "./Settings";

import styles from './Header.module.css';

interface Props {
  currentScore?: number;
  bestScore?: number;
  cycleColorPalette: () => void;
}

export const Header: React.FC<Props> = ({
  currentScore,
  bestScore,
  cycleColorPalette,
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.spacer}/>
      <Score
        setsCreated={currentScore ?? 0}
        longestRun={bestScore ?? 0}
      />

      <Settings />
    </div>
  );
};
