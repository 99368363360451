import styles from './Score.module.css';

interface Props {
  setsCreated: number;
  longestRun: number;
}

export const Score: React.FC<Props> = ({ setsCreated, longestRun }) => {
  return (
    <div className={styles.scoreboard}>
      <div className={styles.score}>
        <div>Score</div>
        <div>{setsCreated}</div>
      </div>
      <div className={styles.score}>
        <div>Best</div>
        <div>{longestRun}</div>
      </div>
    </div>
  );
};
