import { Card, InvalidSetInfo, ValidSetInfo } from "./types";

const getRandomItem = (arr: any[]) =>
  arr[Math.floor(Math.random() * arr.length)];

const generateCard = () => {
  return {
    number: getRandomItem([1, 2, 3]),
    shape: getRandomItem(['oval', 'diamond', 'rectangle']),
    color: getRandomItem(['red', 'green', 'blue']),
    fill: getRandomItem(['solid', 'empty', 'half']),
  };
};

/**
 * Returns four-character string from the given `Card`.
 * @param Card
 * @returns a string in the order `color`, `number`, `shape`, `fill`
 */
export const cardToString = (card: Card): string => {
  const color = card.color.substring(0, 1);
  const fill = card.fill.substring(0, 1);
  const number = card.number;
  const shape = card.shape.substring(0, 1);
  return `${color}${number}${shape}${fill}`;
};

/**
 * Returns a `Card` from a given string. `cardString` is a 4-letter string
 * consisting of the first character of each property in the order `color`,
 * `number`, `shape`, `fill`. For example, the string `r3os` would return
 * a card with three red solid ovals.
 * @param cardString
 * @returns Card
 */
export const cardFromString = (cardString: string): Card => {
  const [color, number, shape, fill] = cardString.split('');
  
  const newCard: Card = {
    number: 1,
    color: 'red',
    shape: 'oval',
    shapeName: 'circle',
    fill: 'empty',
    fillName: 'empty',
  };

  if (color === 'g') newCard.color = 'green';
  if (color === 'b') newCard.color = 'blue';

  if (number === '2') newCard.number = 2;
  if (number === '3') newCard.number = 3;

  if (shape === 'r') newCard.shape = 'rectangle';
  if (shape === 'd') newCard.shape = 'diamond';

  if (fill === 's') newCard.fill = 'solid';
  if (fill === 'h') newCard.fill = 'half';

  if (newCard.shape === 'rectangle') newCard.shapeName = 'square';
  if (newCard.shape === 'diamond') newCard.shapeName = 'diamond';

  if (newCard.fill === 'solid') newCard.fillName = 'solid';
  if (newCard.fill === 'half') newCard.fillName = 'stripe';

  return newCard;
};

export const generateUniqueCards = (length = 12, cards?: Set<string>): Set<string> => {
  const newSet = new Set<string>();

  while (newSet.size < length) {
    const card = cardToString(generateCard());
    if (!cards?.has(card)) newSet.add(card);
  }

  return newSet;
};

export const isValidSet = (cards: Card[]): ValidSetInfo | InvalidSetInfo => {
  if (cards.length !== 3) return { valid: false }; // set has to have 3 cards

  const shapes = Array.from(new Set(cards.map(c => c.shape)));
  const colors = Array.from(new Set(cards.map(c => c.color)));
  const numbers = Array.from(new Set(cards.map(c => c.number)));
  const fills = Array.from(new Set(cards.map(c => c.fill)));

  const allShapesSame = shapes.length === 3;
  const allShapesDifferent = shapes.length === 1;
  const allColorsSame = colors.length === 3;
  const allColorsDifferent = colors.length === 1;
  const allNumbersSame = numbers.length === 3;
  const allNumbersDifferent = numbers.length === 1;
  const allFillsSame = fills.length === 3;
  const allFillsDifferent = fills.length === 1;

  return {
    valid: (allShapesSame || allShapesDifferent) &&
            (allColorsSame || allColorsDifferent) &&
            (allNumbersSame || allNumbersDifferent) &&
            (allFillsSame || allFillsDifferent),
    allShapesSame,
    allShapesDifferent,
    allColorsSame,
    allColorsDifferent,
    allNumbersSame,
    allNumbersDifferent,
    allFillsSame,
    allFillsDifferent,
  };

  // values split out to allow for some kind of help feature when
  // learning to play

  //        all same   |   all different  |  valid prop
  // shape     👍      |         🚫        |      👍
  // color     🚫      |         👍        |      👍
  // fill      🚫      |         🚫        |      🚫
  // number    👍      |         🚫        |      👍
};
