import { Card as CardProps, Color, Fill, Shape } from "./types";

import styles from './Card.module.css';
import { useContext } from "react";
import { ColorPaletteContext } from "./ColorContext";

interface Props extends CardProps {
  onClick: () => void;
  active?: boolean;
  index: number;
  styles?: React.CSSProperties;
}

interface ShapeProps {
  color: Color;
  fillType: Fill;
  shape: Shape
}

const BaseShape: React.FC<ShapeProps> = ({
  color,
  fillType,
  shape,
}) => {
  const { colorPalette } = useContext(ColorPaletteContext);
  const colors = {
    ...colorPalette,
    white: 'rgba(255, 255, 255, 0.9)',
    black: 'rgba(0, 0, 0, 0.8)',
  }
  const fillColor = ['solid', 'half'].includes(fillType) ? colors[color] : '#fff';
  const strokeColor = colors[color];
  const cssStyles: {[key: string]: string} = {
    '--border-color': strokeColor,
    '--background-color': fillColor,
    width: shape === 'diamond' ? '29.6%' : '35.2%',
    height: shape === 'diamond' ? '18.5%' : '22%',
  };

  if (fillType === 'half' && shape === 'diamond') {
    cssStyles['--stripe-angle'] =  '75deg';
    cssStyles['--stripe-width'] = '26%';
    // cssStyles['--stripe-width'] = '16px';
  }

  let shapeClass = styles.cardShapeOval;
  if (shape === 'diamond') shapeClass = styles.cardShapeDiamond;
  if (shape === 'rectangle') shapeClass = styles.cardShapeRectangle;

  const cssClasses =
    `${shapeClass} ${fillType === 'half' ? styles.halfFill : ''}`

  return (
    <div
      className={cssClasses}
      style={cssStyles as React.CSSProperties}
    />
  );
};

const Oval: React.FC<{ color: Color, fill: Fill }> = ({
  color,
  fill,
}) => {
  return (
    <BaseShape
      color={color}
      fillType={fill}
      shape="oval"
    />
  );
};

const Rectangle: React.FC<{ color: Color, fill: Fill }> = ({ color, fill }) => {
  return (
    <BaseShape
      color={color}
      fillType={fill}
      shape="rectangle"
    />
  );
};

const Diamond: React.FC<{ color: Color, fill: Fill }> = ({ color, fill }) => {
  return (
    <BaseShape
      color={color}
      fillType={fill}
      shape="diamond"
    />
  );
};

export const Card = ({
  active,
  color,
  fill,
  number,
  onClick,
  shape,
  styles: cssStyles,
}: Props) => {
  const { colorPalette } = useContext(ColorPaletteContext);
  const cssClasses = `${styles.card} ${active ? styles.activeCard : ''}`;

  const colors = {
    ...colorPalette,
    white: 'rgba(255, 255, 255, 0.9)',
    black: 'rgba(0, 0, 0, 0.8)',
  }

  let CardShape = Oval;
  if (shape === 'rectangle') CardShape = Rectangle;
  if (shape === 'diamond') CardShape = Diamond;

  return (
    <div
      className={cssClasses}
      style={{
        ...cssStyles,
        '--card-color': colors[color],
      } as React.CSSProperties}
    >
      <div
        className={styles.cardFront}
        onClick={onClick}
      >
        {Array.from({ length: number }).map((item, idx) =>
          <CardShape
            key={`shape-${idx}`}
            color={color}
            fill={fill}
          />
        )}
      </div>
      <div className={styles.cardBack} />
    </div>
  );
};
